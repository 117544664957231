import { TentTypeV1, getDateString } from '@camp67/model';
import { environmentState } from '../state/environment';
import { retry } from '../retry';
import { propagateError } from '../propagate-error';

export async function queryTentAvailability(startDate: string, endDate: string) {
  const base = environmentState.apiUrl;
  const url = new URL(base);
  url.pathname += 'api/reservation/availability';

  const response = await retry('query-availability', (signal) =>
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        startDate: getDateString(startDate),
        endDate: getDateString(endDate),
      }),
      credentials: 'include',
      signal,
    }),
  );

  if (!response.ok) {
    const error = new Error(response.statusText);
    propagateError(error, 'tent availability');

    throw error;
  }

  const tents = await response.json<TentTypeV1[]>();
  return tents;
}

type RequestDateAvailabilityOpts = {
  rooms: [TentTypeV1, number][];
};

export type CheckinCheckoutMap = Map<string, [string, number]>;
export type RoomsAvailabilityMap = Map<string, CheckinCheckoutMap>;

export async function queryTentAvailabilityDates(
  opts: RequestDateAvailabilityOpts,
): Promise<RoomsAvailabilityMap> {
  const base = environmentState.apiUrl;
  const url = new URL(base);
  url.pathname += 'api/reservation/availability/dates';

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(opts),
    credentials: 'include',
  });

  // if (!response.ok) {
  //   const error = new Error(response.statusText);
  //   propagateError(error, 'tent availability dates');

  //   throw error;
  // }

  const data = await response.json<[string, [string, [string, number]][]][]>();
  const roomsAvailabilityMap = new Map<string, CheckinCheckoutMap>();
  for (const [date, rooms] of data) {
    const roomMap = new Map<string, [string, number]>();
    for (const [room, checkinCheckout] of rooms) {
      roomMap.set(room, checkinCheckout);
    }
    roomsAvailabilityMap.set(date, roomMap);
  }

  console.log(roomsAvailabilityMap);

  // if (
  //   opts.rooms.some(
  //     ([room]) => room === TentTypeV1.DORM_BUNK || room === TentTypeV1.DORM_SINGLE,
  //   )
  // ) {
  // }
  // return dates.filter((date) => new Date(date) >= new Date('2025-01-19'));

  return roomsAvailabilityMap;
}
